<template>
  <div>
    <div class="welcome-back-wrapper">
      <div class="text-header-1">
        Welcome back {{ customerFirstName }}! 👋
        <div v-if="rewardBalance" class="reward-balance">
          <span>You have {{ rewardBalance }} available in your rewards balance to save on this service. 🎉</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WelcomeBack',
  props: {
    customerFullName: {
      type: String,
      required: true,
    },
    rewardBalance: {
      type: String,
      required: false,
    },
  },
  computed: {
    customerFirstName() {
      return this.customerFullName.split(' ')[0];
    },
  },
};
</script>

<style style="scss">
.welcome-back-wrapper {
  padding: 0 16px;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.reward-balance {
  margin-top: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #036945;
  background-color: #b9f9e2;
  padding: 8px;
  text-align: center;
  border-radius: 8px;
}
</style>
