import apiClient from '@/client';

export default {
  get: {
    index: (shop, params = {}) => {
      return apiClient.get(`/public/accounts/${shop}/services`, { params });
    },
    shopInfo: (slug) => {
      return apiClient.get(`/public/accounts/${slug}`);
    },
    shopExtraInfo: (slug) => {
      return apiClient.get(`/public/accounts/${slug}/extra`);
    },
    availabilityData: (slug, appointmentType, services, daysAhead = 30, startDate = undefined) => {
      return apiClient.get(`/public/accounts/${slug}/availability`, {
        params: {
          days_ahead: daysAhead,
          appointment_type: appointmentType,
          services,
          start_date: startDate,
        },
      });
    },
  },
};
