import axios from 'axios';

const partnerId = process.env.VUE_APP_GOOGLE_CONVERSION_PARTNER_ID;
const appEnv = process.env.VUE_APP_APP_ENV ?? 'staging';

export default {
    post: {
        conversion: (rwgToken) => {
            if(appEnv !== 'production') return;

            // We don't allow users to change the shop if they go through the google booking link,
            // so we can assume the merchant didn't change

            return axios.post(`https://www.google.com/maps/conversion/collect`, {
                rwg_token: rwgToken,
                conversion_partner_id: partnerId,
                merchant_changed: 2
            }, {
                headers: {
                    'Content-Type': false
                }
            });
        }
    },
};