<template>
  <div class="layout-main-wrapper">
    <transition name="fade">
      <div v-if="selectedAccount" class="desktop-location-logo-wrapper">
        <div v-if="!selectedAccount.logo" class="location-name">
          {{ selectedAccount.name }}
        </div>
        <div v-else class="location-logo-img-wrapper">
          <img :src="selectedAccount.logo" alt="location-logo" />
        </div>
      </div>
    </transition>

    <div class="general-repair-layout">
      <custom-wizard-heading
        :title="headerTitle"
        :customer-name="name"
        :subtitle="headerSubtitle"
        :active-index="$refs.generalRepairWizard?.activeTabIndex"
        :is-mso="isMSO"
        :displayed-second-step-substep="displayedSecondStepSubstep"
        :is-last-step="showAppointmentBookedScreen"
        :locations-show-list-view="locationsShowListView"
        :locations-show-map-view="locationsShowMapView"
        :show-exit-survey="showExitSurvey"
        @show-locations-list-view="handleShowLocationsListView"
        @show-locations-map-view="handleShowLocationsMapView"
        @prev-tab="handleHeadingGoToPrevTab"
        @goto-exit-survey="handleShowExitSurvey"
        @send-abandoned-appointment-notification="sendAbandonedAppointmentNotification"
      />
      <template v-if="!showExitSurvey">
        <custom-wizard-progress
          v-if="!showAppointmentBookedScreen"
          :total-steps="4"
          :current-step="+$refs.generalRepairWizard?.activeTabIndex || 0"
        />
        <pit-crews-rewards
          v-if="
            !selectVehicleStepShowWelcomeBackScreen &&
            recognisedCustomerSlug &&
            rewardBalance &&
            !showAppointmentBookedScreen
          "
          :reward-balance="computedRewardBalance"
        />

        <transition name="fade-fastest" mode="out-in">
          <form-wizard
            v-if="!showAppointmentBookedScreen"
            color="#377DFF"
            ref="generalRepairWizard"
            :subtitle="null"
            :title="null"
            shape="circle"
            :hide-buttons="true"
          >
            <tab-content class="fade-in" title="Service">
              <select-location-step
                :show-ask-for-location-permissions-card="showAskForLocationPermissionsCard"
                :client-location="clientLocation"
                :map-view-transition="locationsMapViewTransition"
                :show-list-view="locationsShowListView"
                :show-map-view="locationsShowMapView"
                @close-ask-for-permissions="handleCloseAskForPermissions"
                @location-selected="handleLocationSelected"
              />
            </tab-content>

            <tab-content class="fade-in" title="Drop off">
              <select-service-step
                v-if="selectedAccount"
                :key="selectedAccount.slug"
                :account-diagnostic-data="accountDiagnosticApptData"
                :displayed-substep="displayedSecondStepSubstep"
                :account-slug="selectedAccount.slug"
                :diagnostic-appointment-feature-enabled="diagnosticAppointmentFeatureEnabled"
                @header-title-change="handleHeaderTitleChange"
                @display-diagnostic-appointment-survey-substep="handleDisplayDiagnosticAppointmentSurveySubstep"
                @display-select-service-substep="handleDisplaySelectServiceSubstep"
                @display-diagnostic-additional-info-substep="handleDisplayDiagnosticAdditionalInfoSubstep"
                @display-service-additional-notes-substep="handleDisplayServiceAdditionalNotesSubstep"
                @service-selected="(payload) => handleServiceSelected(payload)"
                @diagnostic-info-submitted="handleDiagnosticInfoSubmitted"
              />
            </tab-content>

            <tab-content class="fade-in">
              <select-appointment-time-step
                v-if="$refs.generalRepairWizard?.activeTabIndex === 2 && !this.rotateCustomerStep"
                :account-slug="selectedAccount.slug"
                :account-number="selectedAccount?.company_phone"
                :account-name="selectedAccount?.name"
                :key="
                  selectedAccount.slug + selectedServices.length ? selectedServices.map((s) => s.slug).join('-') : ''
                "
                :waiting-mode-enabled="accountHasWaitingModeEnabled"
                :drop-off-policy="accountDropOffPolicy"
                :selected-services="selectedServices"
                :selected-diagnostic-service="selectedDiagnosticService"
                :customer-ride-options="customerRideHomeOptions"
                :show-customer-ride-options-substep="showCustomerRideOptionsSubstep"
                @appointment-date-input="handleAppointmentDateInput"
                @select-appointment-time="selectAppointmentTime"
                @show-customer-ride-options="handleShowCustomerRideOptions"
                @next-step="handleAppointmentTimeSelected"
              />
              <customer-info-step
                v-if="$refs.generalRepairWizard?.activeTabIndex === 2 && this.rotateCustomerStep"
                :account-name="selectedAccount?.name"
                :account-otp-setting="selectedAccount?.otp_service"
                :account-slug="selectedAccount?.slug"
                :require-address="requiredCustomerAddress"
                first-step
                @next-step="handleCustomerInfoFilled"
                @start-abandonment-timer="handleStartAbandonmentTimer"
              />
            </tab-content>

            <tab-content class="fade-in" title="Customer Info">
              <customer-info-step
                v-if="$refs.generalRepairWizard?.activeTabIndex === 3 && !this.rotateCustomerStep"
                :account-name="selectedAccount?.name"
                :account-otp-setting="selectedAccount?.otp_service"
                :account-slug="selectedAccount?.slug"
                :require-address="requiredCustomerAddress"
                @next-step="handleCustomerInfoFilled"
                @start-abandonment-timer="handleStartAbandonmentTimer"
              />
              <select-vehicle-step
                v-if="selectedAccount && this.rotateCustomerStep"
                :customer-name="name"
                :show-customer-recognised-screen="selectVehicleStepShowWelcomeBackScreen"
                :recognised-vehicles="recognisedVehicles"
                :account-slug="selectedAccount.slug"
                :is-add-additional-services-option-enabled="isAddAdditionalServicesOptionEnabled"
                :show-deferred-jobs="selectVehicleStepShowRecommendedServices"
                @display-deferred-jobs="selectVehicleStepShowRecommendedServices = true"
                @vehicle-selected="handleVehicleSelected"
                :reward-balance="computedRewardBalance"
              />
            </tab-content>

            <tab-content class="fade-in" title="Vehicle">
              <select-vehicle-step
                v-if="selectedAccount && !this.rotateCustomerStep"
                :customer-name="name"
                :show-customer-recognised-screen="selectVehicleStepShowWelcomeBackScreen"
                :recognised-vehicles="recognisedVehicles"
                :account-slug="selectedAccount.slug"
                :is-add-additional-services-option-enabled="isAddAdditionalServicesOptionEnabled"
                :show-deferred-jobs="selectVehicleStepShowRecommendedServices"
                @display-deferred-jobs="selectVehicleStepShowRecommendedServices = true"
                @vehicle-selected="handleVehicleSelected"
                show-book-now-button
                :reward-balance="computedRewardBalance"
              />
              <select-appointment-time-step
                v-if="$refs.generalRepairWizard?.activeTabIndex === 4 && this.rotateCustomerStep"
                :account-slug="selectedAccount.slug"
                :account-number="selectedAccount?.company_phone"
                :account-name="selectedAccount?.name"
                :key="
                  selectedAccount.slug + selectedServices.length ? selectedServices.map((s) => s.slug).join('-') : ''
                "
                :waiting-mode-enabled="accountHasWaitingModeEnabled"
                :drop-off-policy="accountDropOffPolicy"
                :selected-services="selectedServices"
                :selected-diagnostic-service="selectedDiagnosticService"
                :customer-ride-options="customerRideHomeOptions"
                :show-customer-ride-options-substep="showCustomerRideOptionsSubstep"
                @appointment-date-input="handleAppointmentDateInput"
                @select-appointment-time="selectAppointmentTime"
                @show-customer-ride-options="handleShowCustomerRideOptions"
                @next-step="handleAppointmentTimeSelected"
                show-book-now-button
              />
            </tab-content>
          </form-wizard>

          <appointment-booked-screen
            v-else
            :account-slug="selectedAccount.slug"
            :selectedAccountLocation="{
              lat: selectedAccount.lat,
              lng: selectedAccount.lng,
            }"
            :appointment-type="appointmentType"
            :drop-off-policy="accountDropOffPolicy"
            :appointment-time="appointmentTime"
            :selected-services="selectedServices"
            :selected-service-additional-notes="selectedServiceAdditionalNotes"
            :diagnostic-price="diagnosticPrice"
            :requested-service-description="getSurveyAnswersFilledDescription()"
            :deferred-jobs="selectedDeferredJobs"
          />
        </transition>
      </template>

      <div v-if="showExitSurvey" class="booking-steps">
        <exit-survey
          :account-slug="selectedAccount?.slug"
          :customer-name="name"
          :customer-phone="phone"
          :account-name="selectedAccount?.name"
          :is-shop-open="selectedAccount?.currently_open"
          :account-phone="selectedAccount?.company_phone"
        />
      </div>

      <shopgenie-footer />
    </div>

    <desktop-footer :account-slug="selectedAccount?.slug" />
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard';
import CustomWizardProgress from '@/components/CustomWizardProgress';
import PitCrewsRewards from '@/components/PitCrewsRewards';
import CustomWizardHeading from '@/components/CustomWizardHeading';
import SelectLocationStep from '@/components/select-location-step/Index';
import SelectServiceStep from '@/components/select-service-step/Index';
import SelectAppointmentTimeStep from '@/components/select-appt-time-step/Index';
import CustomerInfoStep from '@/components/customer-info-step/Index';
import SelectVehicleStep from '@/components/select-vehicle-step/Index';
import AppointmentBookedScreen from '@/components/AppointmentBookedScreen';
import ExitSurvey from '../components/ExitSurvey';
import ShopgenieFooter from '@/components/ShopgenieFooter';
import DesktopFooter from '@/components/DesktopFooter';
import CompanyService from '@/services/CompanyService';
import PitCrewService from '@/services/PitCrewService';
import AppointmentsService from '@/services/AppointmentsService';
import GoogleService from '@/services/GoogleService';

const defaultAbandonmentMinutes = 10;

export default {
  name: 'GeneralRepair',
  components: {
    CustomWizardProgress,
    CustomWizardHeading,
    PitCrewsRewards,
    FormWizard,
    TabContent,
    SelectLocationStep,
    SelectServiceStep,
    SelectAppointmentTimeStep,
    CustomerInfoStep,
    SelectVehicleStep,
    AppointmentBookedScreen,
    ShopgenieFooter,
    DesktopFooter,
    ExitSurvey,
  },
  data() {
    return {
      isMSO: true,
      canSendAbandonedAppointmentNotification: true,
      startAbandonmentTimer: false,
      beforeUnload: false,
      lastActivityTime: Date.now(),
      isUserInactive: false,
      inactivityThreshold: (process.env.VUE_APP_ABANDONED_APPOINTMENT_MINUTES || defaultAbandonmentMinutes) * 60 * 1000, // 10 min default converted from milliseconds to minutes
      locationsMapViewTransition: '',
      locationsDisplatedViewTransitioning: false,
      locationsShowListView: false,
      locationsShowMapView: true,
      showAskForLocationPermissionsCard: true,
      clientLocation: null,
      selectedAccount: null,
      headerTitle: 'Select a location',
      headerSubtitle: null,
      // select-service | diagnostic-additional-info | service-additional-notes | diagnostic-appointment-survey
      displayedSecondStepSubstep: 'select-service',
      showCustomerRideOptionsSubstep: false,
      showExitSurvey: false,
      selectedServices: [],
      selectedDiagnosticService: null,
      selectedServiceAdditionalNotes: '',
      selectedServiceAttachment: null,
      diagnosticSurveyAnswers: [],
      requestedServiceDescription: '',
      attachments: null,
      appointmentTime: '',
      appointmentType: '',
      customerRequestedRideHome: '',
      recognisedCustomerSlug: '',
      name: '',
      email: '',
      phone: '',
      address: '',
      recognisedVehicles: [],
      termsAccepted: false,
      selectVehicleStepShowWelcomeBackScreen: false,
      selectVehicleStepShowRecommendedServices: false,
      selectedDeferredJobs: [],
      showAppointmentBookedScreen: false,
      diagnosticPrice: {
        from: 0,
        to: 0,
      },
      googleRecaptchaToken: null,
      vehicleInfo: {
        year: '',
        make: '',
        model: '',
        licensePlate: '',
        deferredJobs: '',
        recognisedVehicleExternalId: '',
      },
      rewardBalance: null,
      rotate_steps: false,
    };
  },
  mounted() {
    this.fetchCompanyData();

    window.addEventListener('message', this.handleEmbedCodeMessages);

    this.$watch(
      () => {
        return this.$refs.generalRepairWizard?.activeTabIndex;
      },
      (stepNumber) => {
        const headerTitles = [
          'Select a location',
          'Book an appointment',
          'Schedule appointment',
          'Add contact info',
          'Add vehicle info',
        ];

        if (!this.isMSO) {
          headerTitles[1] = this.selectedAccount?.name || headerTitles[1];
        }

        // if (
        //   (!this.isMSO && +stepNumber === 1) ||
        //   (this.isMSO && +stepNumber === 0)
        // ) {
        //   this.headerTitle =
        //     this.selectedAccount?.name || headerTitles[stepNumber];
        //   return;
        // }

        if (+stepNumber === 4 && this.recognisedVehicles.length) {
          this.$posthog.capture('vehicleSelection_*_pageview');
          this.headerTitle = `Welcome back ${this.name.split(' ').filter(Boolean).at(0)}!`;
          return;
        }

        this.headerTitle = headerTitles[stepNumber];

        switch (this.headerTitle) {
          case 'Select a location':
            this.$posthog.capture('locationPicker_*_pageview');
            break;
          case 'Book an appointment':
            this.$posthog.capture('serviceSelection_*_pageview');
            break;
          case 'Schedule appointment':
            this.$posthog.capture('datetimeSelection_*_pageview');
            break;
          case 'Add contact info':
            this.$posthog.capture('addPhoneNumber_*_pageview');
            break;
          case 'Add vehicle info':
            this.$posthog.capture('vehicleSelection_*_pageview');
            break;
        }

        if (
          ['Book an appointment', this.selectedAccount?.name].includes(this.headerTitle) &&
          !['diagnostic-additional-info', 'service-additional-notes'].includes(this.displayedSecondStepSubstep)
        ) {
          this.headerSubtitle = 'Select all services that apply';
        } else {
          this.headerSubtitle = null;
        }
      },
    );

    //Check for inactivity
    const activityEvents = ['mousemove', 'keydown', 'scroll'];
    const updateActivityTime = () => {
      this.lastActivityTime = Date.now();
    };
    activityEvents.forEach((event) => {
      window.addEventListener(event, updateActivityTime);
    });

    this.checkInactivityInterval = setInterval(() => {
      const currentTime = Date.now();
      const timeSinceLastActivity = currentTime - this.lastActivityTime;
      this.isUserInactive = timeSinceLastActivity > this.inactivityThreshold;
    }, 1000);
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  watch: {
    isUserInactive(newVal) {
      if (
        newVal &&
        this.startAbandonmentTimer &&
        this.canSendAbandonedAppointmentNotification &&
        !this.showAppointmentBookedScreen
      ) {
        this.sendAbandonedAppointmentNotification();
      }
    },
  },
  unmounted() {
    window.removeEventListener('message', this.handleEmbedCodeMessages);
    //Clear interval
    const activityEvents = ['mousemove', 'keydown', 'scroll'];
    const updateActivityTime = () => {
      this.lastActivityTime = Date.now();
    };
    activityEvents.forEach((event) => {
      window.removeEventListener(event, updateActivityTime);
    });
    clearInterval(this.checkInactivityInterval);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  computed: {
    computedRewardBalance() {
      return this.convertRewardBalanceToDollars(this.rewardBalance);
    },
    rotateCustomerStep() {
      return this.rotate_steps;
    },
    isEmbeddedInCrm() {
      return !!this.$route.query.crm_embed;
    },
    isDesktop() {
      const { desktop } = this.$route.query;
      if (desktop === undefined) return false;
      return !!Number(desktop);
    },
    companySlug() {
      return this.$route.query.shop || null;
    },
    accountHasWaitingModeEnabled() {
      return !!(this.selectedServices.length === 1 && this.selectedServices.at(0).allow_waiters);
    },
    accountDropOffPolicy() {
      if (!this.selectedAccount || !this.selectedAccount.booking_early_drop_off_data) {
        return null;
      }

      const settings = this.selectedAccount?.booking_early_drop_off_data?.value;

      if (!settings || !settings?.policy_enabled || !settings?.policy) {
        return null;
      }

      return settings.policy;
    },
    requiredCustomerAddress() {
      if (!this.selectedAccount || !this.selectedAccount.booking_require_customer_address) {
        return false;
      }

      return !!parseInt(this.selectedAccount.booking_require_customer_address.value);
    },
    accountDiagnosticApptData() {
      if (!this.selectedAccount) {
        return null;
      }

      return this.selectedAccount?.booking_diagnostic_appointment_data?.value || null;
    },
    customerRideHomeOptions() {
      if (
        !this.selectedAccount ||
        !this.selectedAccount.booking_early_drop_off_data ||
        !this.selectedAccount?.booking_early_drop_off_data?.value
      ) {
        return [];
      }

      const options = [{ title: "I've got a ride already", savedTitle: 'No ride requested' }];
      const settings = this.selectedAccount?.booking_early_drop_off_data?.value;

      if (settings.loaner_vehicle_request_enabled) {
        options.push({
          title: 'Request a loaner vehicle',
          savedTitle: 'Loaner vehicle requested',
          description: settings.loaner_vehicle_request_description || '',
        });
      }

      if (settings.ride_share_requests_enabled) {
        options.push({
          title: 'Ride share service',
          savedTitle: 'Rideshare requested',
          description: settings.ride_share_requests_description || '',
        });
      }

      if (settings.shuttle_service_request_enabled) {
        options.push({
          title: 'Shuttle service',
          savedTitle: 'Shuttle requested',
          description: settings.shuttle_service_request_description || '',
        });
      }

      return options;
    },
    isAddAdditionalServicesOptionEnabled() {
      if (!this.selectedAccount || !this.selectedAccount.booking_recommended_services_enabled) {
        return false;
      }

      const enabledFromSettigns = !!parseInt(this.selectedAccount.booking_recommended_services_enabled.value);

      return !!enabledFromSettigns;
    },
    diagnosticAppointmentFeatureEnabled() {
      if (!this.selectedAccount || !this.selectedAccount.booking_diagnostic_appointment_enabled) {
        return false;
      }

      return !!parseInt(this.selectedAccount.booking_diagnostic_appointment_enabled.value);
    },
  },
  methods: {
    async fetchCompanyData() {
      try {
        const response = await CompanyService.get.show(this.companySlug);
        const companyData = response.data.data;
        document.title = companyData.name;
        this.$posthog.group('company', this.companySlug, {
          name: companyData.name,
          companySlug: this.companySlug,
        });
      } catch (error) {
        console.error('An error occurred while fetching company data:', error);
      }
    },

    async fetchPitCrewData() {
      if (!this.recognisedCustomerSlug || !this.selectedAccount?.slug || !this.selectedAccount?.pitcrew_enabled) {
        return;
      }

      try {
        const response = await PitCrewService.get.rewards(this.recognisedCustomerSlug, this.selectedAccount.slug);

        if (response.data.success) {
          this.rewardBalance = response.data.data.balance;
        }

        return response.data.data;
      } catch (error) {
        this.rewardBalance = null;
        this.$posthog.capture('fetchPitCrewData_error', {
          errorMessage: error.message,
        });
      }
    },

    convertRewardBalanceToDollars(rewardBalance) {
      if (rewardBalance) {
        let USDollar = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        return USDollar.format(rewardBalance);
      }
      return null;
    },

    handleEmbedCodeMessages({ data: { name: eventName, payload } }) {
      if (eventName === 'embedCode.permissionsDenied') {
        this.$posthog.capture('locationPicker_*_requestRejected');
        this.showAskForLocationPermissionsCard = false;
        return;
      }

      if (eventName === 'embedCode.permissionsGranted') {
        this.showAskForLocationPermissionsCard = false;
        this.$posthog.capture('locationPicker_*_requestAccepted');
        this.clientLocation = payload;
      }
    },

    handleShowLocationsListView() {
      if (this.locationsDisplatedViewTransitioning || this.locationsShowListView) {
        return;
      }

      this.locationsShowMapView = false;
      this.locationsShowListView = true;
    },

    handleShowLocationsMapView() {
      this.locationsDisplatedViewTransitioning = true;
      this.locationsShowListView = false;

      setTimeout(() => {
        this.locationsShowMapView = true;
        this.locationsDisplatedViewTransitioning = false;
      }, 250);
    },

    handleCloseAskForPermissions() {
      if (this.locationsShowMapView) {
        this.handleShowLocationsListView();
      }

      this.showAskForLocationPermissionsCard = false;
    },

    handleShowExitSurvey() {
      this.showExitSurvey = !this.showExitSurvey;
    },
    handleStartAbandonmentTimer({ name = '', phone = '', email = '' }) {
      this.name = name;
      this.phone = phone;
      this.email = email;
      this.startAbandonmentTimer = true;
    },
    disableCanSendAbandonedAppointmentNotification() {
      this.canSendAbandonedAppointmentNotification = false;
    },
    handleBeforeUnload(event) {
      if (window.location.search.includes('crm_embed')) return;

      if (this.startAbandonmentTimer) {
        event.preventDefault();
        this.beforeUnload = true;
        event.returnValue = 'Are you sure you want to leave?';
        this.sendAbandonedAppointmentNotification();
      }
    },
    disableBeforeUnload() {
      window.removeEventListener('beforeunload', this.handleBeforeUnload);
    },
    async sendAbandonedAppointmentNotification() {
      if (!this.canSendAbandonedAppointmentNotification) return;
      if (window.location.search.includes('crm_embed')) return;
      const abandonedNotificationPayload = {
        account_slug: this.selectedAccount?.slug,
        customer_name: this.name || '',
        customer_phone: this.phone,
        customer_email: this.email || '',
        account_name: this.selectedAccount?.name,
        selected_services: this.selectedServices.map((service) => service.service_name),
        selected_service_additional_notes: this.selectedServiceAdditionalNotes || '',
        appointment_time: this.appointmentTime || '',
        appointment_type: this.appointmentType || '',
      };

      if (this.startAbandonmentTimer && (this.isUserInactive || this.showExitSurvey || this.beforeUnload)) {
        this.disableCanSendAbandonedAppointmentNotification();
        try {
          await AppointmentsService.post.abandonAppointment(abandonedNotificationPayload);
          this.$posthog.capture('appointment_abandoned'), { account_name: this.selectedAccount?.name };
          this.disableBeforeUnload();
        } catch (error) {
          console.error(error);
        }
      }
      return;
    },
    getLocationSearchUtmParams() {
      const urlSearchParams = new URLSearchParams(window.location.search);

      return Array.from(urlSearchParams.keys()).reduce((acc, val) => {
        if (!val.startsWith('utm_')) return acc;

        return {
          ...acc,
          [val]: urlSearchParams.get(val),
        };
      }, {});
    },
    handleHeadingGoToPrevTab() {
      if (
        this.$refs.generalRepairWizard.activeTabIndex === 1 &&
        ['diagnostic-additional-info', 'service-additional-notes'].includes(this.displayedSecondStepSubstep)
      ) {
        this.displayedSecondStepSubstep = 'select-service';
        this.headerTitle = 'Book an appointment';
        this.headerSubtitle = 'Select all services that apply';
        return;
      }

      if (this.$refs.generalRepairWizard.activeTabIndex === 2 && this.showCustomerRideOptionsSubstep) {
        this.showCustomerRideOptionsSubstep = false;
        this.headerTitle = 'Schedule appointment';
        return;
      }

      if (this.$refs.generalRepairWizard.activeTabIndex === 4 && this.selectVehicleStepShowRecommendedServices) {
        this.selectVehicleStepShowRecommendedServices = false;
        this.headerTitle = 'Add vehicle info';
        return;
      }

      this.$refs.generalRepairWizard.prevTab();
      this.headerSubtitle = null;
    },

    goToNextStep() {
      this.$refs.generalRepairWizard.nextTab();
    },

    handleLocationSelected(location, isMSO = true) {
      document.title = location.name;
      this.selectedAccount = location;
      this.$posthog.group('account', location.slug, {
        name: location.name,
        accountSlug: location.slug,
        mso: isMSO,
      });
      this.$posthog.onFeatureFlags(() => {
        this.rotate_steps = this.$posthog.getFeatureFlag('rotate_booking_steps') === 'Make-customer-info-first-step';
      });
      this.isMSO = isMSO;
      this.goToNextStep();
    },

    handleDisplayDiagnosticAppointmentSurveySubstep(payload) {
      if (this.selectedServices.length) {
        this.selectedServices = [];
        this.selectedServiceAdditionalNotes = '';
        this.selectedServiceAttachment = null;
      }

      this.selectedDiagnosticService = payload;
      this.displayedSecondStepSubstep = 'diagnostic-appointment-survey';
      this.headerTitle = 'What brings you in?';
      this.headerSubtitle = null;
    },
    handleHeaderTitleChange(title) {
      this.headerTitle = title;
    },

    handleDisplaySelectServiceSubstep() {
      this.displayedSecondStepSubstep = 'select-service';
      this.headerTitle = 'Book an appointment';
      this.headerSubtitle = 'Select all services that apply';
    },

    handleDisplayDiagnosticAdditionalInfoSubstep({ answers }) {
      this.diagnosticSurveyAnswers = answers;
      this.displayedSecondStepSubstep = 'diagnostic-additional-info';
      this.headerTitle = 'Anything else?';
    },

    handleDisplayServiceAdditionalNotesSubstep() {
      this.displayedSecondStepSubstep = 'service-additional-notes';
      this.headerTitle = 'Anything else we should know?';
      this.headerSubtitle = null;
    },

    handleServiceSelected({ services, description = '', selectedFile = null }) {
      // reset diagnostic service
      this.selectedDiagnosticService = null;

      if (this.requestedServiceDescription) {
        this.requestedServiceDescription = '';
        this.attachments = null;
      }

      this.selectedServices = services;
      this.selectedServiceAdditionalNotes = description;
      this.selectedServiceAttachment = selectedFile;

      this.$refs.generalRepairWizard.nextTab();
    },

    handleDiagnosticInfoSubmitted({ description, selectedFile }) {
      this.requestedServiceDescription = description;
      this.attachments = [selectedFile];
      this.$refs.generalRepairWizard.nextTab();
    },

    handleShowCustomerRideOptions() {
      this.showCustomerRideOptionsSubstep = true;
      this.headerTitle = 'Select a ride option';
    },

    async handleAppointmentTimeSelected({ appointmentType, rideOption }) {
      this.appointmentType = appointmentType;
      this.customerRequestedRideHome = rideOption;
      this.goToNextStep();
      if (this.rotateCustomerStep) await this.bookAppointment();
    },

    async handleCustomerInfoFilled(payload) {
      const { name, email, phone, address, recognisedCustomerSlug, recognisedVehicles, termsAccepted } = payload;

      this.name = name;
      this.email = email;
      this.phone = phone;
      this.address = address;
      this.recognisedVehicles = recognisedVehicles;
      this.recognisedCustomerSlug = recognisedCustomerSlug;
      this.termsAccepted = termsAccepted;

      // fetch pitcrew data if enabled to show rewards balance on welcome back screen
      await this.fetchPitCrewData();

      this.goToNextStep();

      if (!recognisedCustomerSlug) return;

      this.selectVehicleStepShowWelcomeBackScreen = true;

      await new Promise((resolve) => setTimeout(resolve, 1000));
      this.selectVehicleStepShowWelcomeBackScreen = false;
    },

    handleAppointmentDateInput(date) {
      this.appointmentDate = date;
    },
    amPmTo24Hour(amPmTime) {
      if (!amPmTime.includes('PM') && !amPmTime.includes('AM')) {
        return amPmTime;
      }
      const date = amPmTime.split(' ')[0];
      const time = amPmTime.split(' ')[1];
      const ampm = amPmTime.split(' ')[2];
      const hours = time.split(':')[0];
      const minutes = time.split(':')[1];
      const hours24 = ampm === 'PM' && parseInt(hours) < 12 ? Number(parseInt(hours)) + 12 : parseInt(hours);

      return `${date} ${hours24.toString().padStart(2, '0')}:${minutes}`;
    },
    selectAppointmentTime(appointmentTime) {
      this.appointmentTime = this.amPmTo24Hour(appointmentTime);
    },

    getSurveyAnswersFilledDescription() {
      let description = '';

      const filteredAnswers = this.diagnosticSurveyAnswers.filter((answer) => answer.toLowerCase() !== 'other');

      if (filteredAnswers.length) {
        description = filteredAnswers.join(' - ');
      }

      if (this.requestedServiceDescription) {
        description += ' - Notes: ' + this.requestedServiceDescription;
      }

      return description;
    },

    handleGoogleRwgToken() {
      const rwgToken = JSON.parse(localStorage.getItem('rwg_token'));

      if (rwgToken === null) return;

      // 30 days
      if (Date.now() - rwgToken.timestamp >= 2592000000) {
        localStorage.removeItem('rwg_token');
        return;
      }

      try {
        GoogleService.post.conversion(rwgToken.token);
      } catch ($e) {
        console.log($e);
      }
    },

    handleGoogleRecaptcha() {
      return new Promise((resolve, reject) => {
        grecaptcha.ready(async () => {
          try {
            const token = await grecaptcha.execute(process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY, { action: 'submit' });
            this.googleRecaptchaToken = token;
            resolve(token);
          } catch (err) {
            console.log(err);
            reject(err);
          }
        });
      });
    },
    async handleVehicleSelected({ year, make, model, licensePlate, deferredJobs, recognisedVehicleExternalId }) {
      this.vehicleInfo = { year, make, model, licensePlate, deferredJobs, recognisedVehicleExternalId };
      if (!this.rotateCustomerStep) await this.bookAppointment();
      else this.goToNextStep();
    },
    async bookAppointment() {
      const { year, make, model, licensePlate, deferredJobs, recognisedVehicleExternalId } = this.vehicleInfo;
      await this.handleGoogleRecaptcha();

      if (!this.googleRecaptchaToken && process.env.NODE_ENV !== 'testing') return;

      this.selectedDeferredJobs = deferredJobs;

      const loading = this.$loading({
        lock: true,
        text: 'Booking appointment',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.85)',
      });

      let appointmentAttachments = null;

      if (this.attachments) appointmentAttachments = this.attachments;

      if (this.selectedServiceAttachment) appointmentAttachments = [this.selectedServiceAttachment];

      const utmParams = this.getLocationSearchUtmParams();

      let service_slugs = this.selectedServices.length ? this.selectedServices.map((service) => service.slug) : [];
      if (!service_slugs.length) {
        service_slugs = [this.selectedDiagnosticService.slug];
      }

      try {
        await AppointmentsService.post.bookAppointment(
          this.googleRecaptchaToken,
          this.appointmentTime,
          this.appointmentType,
          service_slugs,
          this.selectedServiceAdditionalNotes,
          this.getSurveyAnswersFilledDescription(),
          appointmentAttachments,
          this.email,
          this.name,
          this.phone,
          this.address,
          this.recognisedCustomerSlug,
          this.customerRequestedRideHome,
          this.termsAccepted || this.isEmbeddedInCrm,
          year,
          make,
          model,
          recognisedVehicleExternalId,
          this.selectedAccount.slug,
          licensePlate,
          this.selectedDeferredJobs.map(({ id }) => id),
          this.$route.query?.unique_user_id,
          utmParams,
        );

        // Google RWG Token
        this.handleGoogleRwgToken();
        this.disableBeforeUnload();
        this.showAppointmentBookedScreen = true;
        this.$posthog.capture('vehicleSelection_click_bookNowButton');
      } catch (err) {
        this.$posthog.capture('vehicleSelection_*_bookNowFailed', {
          errorMessage: err.message,
        });
      } finally {
        loading.close();
      }
    },
  },
};
</script>
